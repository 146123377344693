import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import type { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import type { ChipType } from "../Chips/Chips";
import { Chip, ChipContainer } from "../Chips/Chips";
import styled, { useTheme } from "styled-components";
import { InvisibleButton } from "../Buttons/Buttons";
import {
  CheckIcon,
  DeSelectAllIcon,
  SelectAllIcon,
  XIcon,
} from "../Icons/Icons";
import { SearchBar } from "../SearchBar/SearchBar";
import { SoftHeader2 } from "../Typography/Typography";
import ReactTooltip from "react-tooltip";

interface IPrefilledChipsProps {
  selectedChips: ChipType[];
  numSelectable?: 1 | "many";
  allChips: any;
  header: string;
  handleClick: any;
  withSelectAll?: boolean;
  ToggleLabel?: string;
  handleApply?: () => void;
  handleClear?: () => void;
  scroll?: boolean;
  // selectedChip?: any;
}

interface IScrollAreaProps {
  scroll: boolean;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  button:nth-child(2) {
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
`;

const ScrollArea = styled.div<IScrollAreaProps>`
  max-height: ${({ scroll }) => (scroll ? "240px" : "auto")};
  overflow-y: auto;
  display: block;
`;

export const PrefilledSelectAllChips = ({
  numSelectable,
  allChips,
  handleClick,
  withSelectAll,
  selectedChips,
  handleApply,
  handleClear,
  ToggleLabel = "regions",
  scroll = false,
}: IPrefilledChipsProps): JSX.Element => {
  const [activeChipArray, setActiveChipArray] =
    useState<ChipType[]>(selectedChips);
  const [checked, setChecked] = useState(false);
  const [isDefaultChecked, setIsDefaultChecked] = useState<any>(false);
  const [checkBoxClicked, setCheckBoxClicked] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const theme = useTheme();
  const handleChange = (val: ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(val.target.value);

  const handleClearInput = () => setSearchQuery("");

  useEffect(() => {
    if (allChips.length > 0) {
      if (selectedChips.length === allChips.length) {
        setIsDefaultChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChips]);

  useEffect(() => {
    setChecked(isDefaultChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultChecked]);

  const handleMultiSelectClick = (e: any) => {
    if (
      // if clicked chip is inside activeChipArray
      activeChipArray.some(
        (obj: any) => obj.name === JSON.parse(e.currentTarget.dataset.chip).name
      )
    ) {
      setActiveChipArray(
        activeChipArray.filter(
          (obj: any) =>
            obj.name !== JSON.parse(e.currentTarget.dataset.chip).name
        )
      );
    } else {
      setActiveChipArray([
        ...activeChipArray,
        JSON.parse(e.currentTarget.dataset.chip),
      ]);
    }
  };
  useEffect(() => {
    if (numSelectable === "many" || withSelectAll) {
      handleClick(
        activeChipArray.map((item: any) => {
          return item;
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChipArray]);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  });

  const handleCheckBoxClick = () => {
    if (!checkBoxClicked) {
      setChecked(!isDefaultChecked);
      setCheckBoxClicked(true);
      if (!isDefaultChecked === false) {
        setActiveChipArray([]);
        handleClick([]);
      } else {
        setActiveChipArray(allChips);
        handleClick(allChips);
      }
    } else {
      setChecked(!checked);
      if (checked === false) {
        setActiveChipArray(allChips);
        handleClick(allChips);
      } else if (checked === true) {
        setActiveChipArray([]);
        handleClick([]);
      }
      setCheckBoxClicked(true);
    }
  };

  const getChipName = (name: string) => {
    if (
      searchQuery.length > 0 &&
      name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    ) {
      const start = name.toLowerCase().indexOf(searchQuery.toLowerCase());
      const end =
        name.toLowerCase().indexOf(searchQuery.toLowerCase()) +
        searchQuery.length;
      return (
        <>
          {name.substring(0, start)}
          <b>{name.substring(start, end)}</b>
          {name.substring(end, name.length)}
        </>
      );
    }
    return name;
  };

  return (
    <>
      <TopWrapper>
        <SearchBar
          placeHolder={`Search ${ToggleLabel}`}
          query={searchQuery}
          handleChange={handleChange}
          handleClearInput={handleClearInput}
        />
        <ButtonContainer>
          {withSelectAll &&
            allChips.filter((chip: any) =>
              chip.name
                .toLocaleLowerCase()
                .includes(searchQuery.toLocaleLowerCase())
            ).length !== 0 && (
              <div style={{ display: "flex" }}>
                <InvisibleButton onClick={handleCheckBoxClick}>
                  <span
                    data-for="select-all-tip"
                    data-tip={checked ? t("Deselect All") : t("Select All")}
                  >
                    {checked ? (
                      <DeSelectAllIcon fill={theme.brandColor} />
                    ) : (
                      <SelectAllIcon />
                    )}
                  </span>
                </InvisibleButton>
                <ReactTooltip id="select-all-tip" effect="solid" />
              </div>
            )}
          {handleClear && (
            <InvisibleButton onClick={handleClear}>
              <XIcon
                width={24}
                height={24}
                fill={theme.destructiveButtonTextColor}
              />
            </InvisibleButton>
          )}
          {handleApply && (
            <InvisibleButton onClick={handleApply}>
              <CheckIcon width={24} height={24} fill={theme.activeToggleBG} />
            </InvisibleButton>
          )}
        </ButtonContainer>
      </TopWrapper>
      <ScrollArea scroll={scroll}>
        <ChipContainer>
          {allChips
            .filter((chip: any) =>
              chip.name
                .toLocaleLowerCase()
                .includes(searchQuery.toLocaleLowerCase())
            )
            .map((chip: any) => {
              if (activeChipArray.some((obj: any) => obj.name === chip.name)) {
                return (
                  <Chip
                    name={chip.name}
                    data-chip={JSON.stringify(chip)}
                    key={chip.name}
                    active={true}
                    onClick={handleMultiSelectClick}
                  >
                    {getChipName(chip.name)}
                  </Chip>
                );
              } else {
                return (
                  <Chip
                    name={chip.name}
                    data-chip={JSON.stringify(chip)}
                    key={chip.name}
                    active={false}
                    onClick={handleMultiSelectClick}
                  >
                    {getChipName(chip.name)}
                  </Chip>
                );
              }
            })}
          {allChips.filter((chip: any) =>
            chip.name
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase())
          ).length === 0 && (
            <SoftHeader2 style={{ margin: "30px 0", textAlign: "center" }}>
              {t("No Results found")}
            </SoftHeader2>
          )}
        </ChipContainer>
      </ScrollArea>
    </>
  );
};
